<script>
export default {
  name: 'InviteCheck',
  data () {
    return {
      inviteInfo: {}
    }
  },
  computed: {
    inviteId () {
      return this.$route.params.inviteId
    }
  },
  methods: {
    checkInvite () {
      this.$store.dispatch('attemptCheckInviteStatus', this.inviteId)
        .then(({ data }) => {
          this.inviteInfo = data
          if (data.status === 'PENDING') {
            this.acceptInvite()
          }
          if (data.status === 'COMPLETED') {
            this.postAcceptRoutine()
          }
        })
    },
    acceptInvite () {
      this.$store.dispatch('attemptAcceptWorkspaceInvite', this.inviteId)
        .then(({ data }) => {
          this.inviteInfo = data
          if (data.status === 'COMPLETED') {
            this.postAcceptRoutine()
          }
        })
        .catch(({ response }) => {
          if (response.data.error === 'invalid_user_id') {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('workspace.invite:error.invalid.user')
            })
            this.clearStorageAndLogout()
          }
          this.$router.push({ name: 'auth.login' })
        })
    },
    postAcceptRoutine () {
      this.$store.dispatch('attemptGetUserWorkspaces')
        .then((data) => {
          this.$store.commit('setSelectedWorkspace', data.find(item => item.id === this.inviteInfo.workspaceId))
          this.$router.push({ name: this.selectedWorkspace.type + '.home.user.contents', params: { newToWorkspace: this.inviteInfo.status } })
        })
    }
  },
  created () {
    if (this.inviteId) {
      this.checkInvite()
    }
  }
}
</script>
<template>
  <div class="invite-check--container">
    <div class="invite-check--body" v-if="inviteInfo.status === 'EXPIRED'">
      <div class="invite-check--panel invite-check--left-panel">
        <v-img
          height="56px"
          width="214px"
          class="menu-company--image__content"
          src="/assets/images/dark-yellow-logo.png"
        />
        <h2>{{ $t('workspace.invite.expired:left.title') }}</h2>
      </div>
      <div class="invite-check--panel invite-check--right-panel">
        <v-card class="invite-check--expired-card">
          <v-img height="200px" width="200px" src="/assets/images/workspace-banners/expired-invite.png"></v-img>
          <h5 class="h6">{{ $t('workspace.invite.expired:title') }}</h5>
          <p class="body--1">{{ $t('workspace.invite.expired:description') }}</p>
        </v-card>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.invite-check--container {
  height: 100vh;
  width: 100vw;
  padding: 64px 110px 64px 64px;
  background-color: $primary-medium;
  .invite-check--body {
    display: flex;
    .invite-check--panel {
      width: 50%;
    }
    .invite-check--left-panel {
      text-align: left;
      .v-image {
        margin-top: 74px;
      }
      h2 {
        color: $neutral-white;
        margin-top: 74px;
      }
    }
    .invite-check--right-panel {
      .invite-check--expired-card {
        width: 510px;
        max-width: 100%;
        margin-left: auto;
        text-align: left;
        background-color: $neutral-white;
        padding: 48px 32px;
        .h6 {
          margin-top: 16px;
          color: $warning-medium;
        }
        .body--1 {
          margin: 0;
          color: $neutral-black;
        }
      }
    }
  }
}
</style>
